import { Box, Grid, Typography } from "@mui/material";
import CustomButton from "../../../components/customButton";
import NavigationDrawer from "../../../components/navigationDrawer";
import DynamicTable from "../../../components/dynamicTable";
import { Navigate, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import { getAllUsers, updateUserStatus } from "../../../services/users";
import Loader from "../../../components/loader";

const User = () => {
  const [userData, setUserData] = useState([]);
  const [isloading, setIsloading] = useState(false);
  useEffect(() => {
    getUsers();
  }, []);
  const getUsers = () => {
    setIsloading(true);
    getAllUsers().then((resss) => {
      setIsloading(false);
      setUserData(resss?.data?.data);
    });
  };
  const headerData = [
    {
      id: "userName",
      numeric: false,
      disablePadding: true,
      label: "Name",
    },
    {
      id: "userEmail",
      numeric: false,
      disablePadding: true,
      label: "Email",
    },
    {
      id: "userContact",
      numeric: false,
      disablePadding: true,
      label: "Phone Number",
    },
    {
      id: "switch",
      numeric: false,
      disablePadding: true,
      label: "Status",
    },
  ];
  const displayRows = ["name", "email", "phoneNumber", "switch"];

  const handleUpdateUser = (event, row) => {
    let payload = {
      status: !row.status ? true : false,
    };
    updateUserStatus(row._id, payload)
      .then((resss) => {
        if (resss?.data?.status == "ok") {
          getUsers();
          Swal.fire({
            text: "Status Update succesfuly",
            icon: "success",
          });
        }
      })
      .catch((err) => {
        console.log(err, "cnsdjsdfdfsdbh");
      });
  };

  let user = localStorage.getItem("userInfo");
  const userInfo = JSON.parse(user);
  if (!userInfo) {
    return <Navigate to="/login" replace={true} />;
  }
  return (
    <NavigationDrawer>
      <Grid container>
        <Grid
          item
          md={12}
          xs={12}
          mb={2}
          sx={{ width: "100%", display: "flex", alignItems: "center" }}
        >
          <Typography variant="h1" sx={{ fontSize: 40 }}>
            Users
          </Typography>
        </Grid>
        <DynamicTable
          headerData={headerData}
          bodyRows={userData}
          displayData={displayRows}
          handleSwitchUpdate={handleUpdateUser}
        />
      </Grid>
      <Loader isloading={isloading} />
    </NavigationDrawer>
  );
};
export default User;
