import React, { useState, useEffect, useImperativeHandle } from "react";
import { DialogContent, TextField, InputLabel, Grid } from "@mui/material";
import useStyles from "../../../globalStyles/index";
import DialogFooter from "./../../../components/dialog/dialogFooter";
import DialogHeader from "./../../../components/dialog/dialogHeader";
import BootstrapDialog from "./../../../components/dialog/index";
import {
  createCitiesByAdmin,
  updateCitiesByAdmin,
} from "../../../services/cities";

const AddEditCities = ({
  inputValue,
  setInputValue,
  open,
  dialogData,
  handleClose,
  type,
  handleSnackbarVal,
  setIsLoading,
  getAllCities,
}) => {
  const classes = useStyles();

  useEffect(() => {
    if (type == "edit") {
      setInputValue({
        cityName: dialogData?.name,
        convienceFees: dialogData?.convenceFees,
        minimumFare: dialogData?.minfare,
        ratePerHour: dialogData?.fareHr,
        ratePerKm: dialogData?.fareKm,
        cityCommission: dialogData?.commisionFees,
      });
    }
  }, [dialogData]);

  const handleChangeInput = (e) => {
    setInputValue({
      ...inputValue,
      [e.target.name]: e.target.value,
    });
  };

  const handleBtn = async () => {
    if (inputValue.cityName == "") {
      handleSnackbarVal(true, "error", "City is required");
    } else {
      let payload = {
        name: inputValue.cityName,
        convenceFees: inputValue.convienceFees ? inputValue.convienceFees : 0,
        minfare: inputValue.minimumFare ? inputValue.minimumFare : 0,
        fareHr: inputValue.ratePerHour ? inputValue.ratePerHour : 0,
        fareKm: inputValue.ratePerKm ? inputValue.ratePerKm : 0,
        commisionFees: inputValue.cityCommission
          ? inputValue.cityCommission
          : 0,
      };
      if (type == "add") {
        setIsLoading(true);
        createCitiesByAdmin(payload)
          .then((ress) => {
            console.log(ress, "CREATECATEGORY");
            setIsLoading(false);
            if (ress?.status == "200") {
              handleClose();
              handleSnackbarVal(true, "success", "City created successfully");
              getAllCities();
            } else {
              handleSnackbarVal(true, "error", ress?.data.message);
            }
          })
          .catch((err) => {
            setIsLoading(false);
            console.log(err, "ERRORCREATECATEGORY");
            handleSnackbarVal(true, "error", err?.message);
          });
      } else {
        setIsLoading(true);
        updateCitiesByAdmin(dialogData._id, payload)
          .then((ress) => {
            console.log(ress, "UPDATECTAEGORY");
            setIsLoading(false);
            if (ress?.status == "200") {
              handleClose();
              handleSnackbarVal(true, "success", "City updated successfully");
              getAllCities();
            } else {
              handleSnackbarVal(true, "error", ress?.data.message);
            }
          })
          .catch((err) => {
            setIsLoading(false);
            console.log(err, "ERRORUPDATECATEGORY");
            handleSnackbarVal(true, "error", err?.message);
          });
      }
    }
  };

  return (
    <div>
      <BootstrapDialog onClose={handleClose} open={open}>
        <DialogHeader
          title={type == "add" ? "Add City" : "Edit City"}
          handleClose={handleClose}
        />
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <InputLabel className={classes.globalInputLabel}>
                City Name
              </InputLabel>
              <TextField
                placeholder="City Name"
                variant="outlined"
                size="small"
                className={classes.globalInput}
                name="cityName"
                value={inputValue.cityName}
                onChange={handleChangeInput}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel className={classes.globalInputLabel}>
                Convience Fees
              </InputLabel>
              <TextField
                placeholder="Convience Fees"
                variant="outlined"
                size="small"
                type="number"
                className={classes.globalInput}
                name="convienceFees"
                value={inputValue.convienceFees}
                onChange={handleChangeInput}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel className={classes.globalInputLabel}>
                Minimum Fare
              </InputLabel>
              <TextField
                placeholder="Minimum Fare"
                variant="outlined"
                size="small"
                type="number"
                className={classes.globalInput}
                name="minimumFare"
                value={inputValue.minimumFare}
                onChange={handleChangeInput}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel className={classes.globalInputLabel}>
                Rate Per Hour
              </InputLabel>
              <TextField
                placeholder="Rate Per Hour"
                variant="outlined"
                size="small"
                type="number"
                className={classes.globalInput}
                name="ratePerHour"
                value={inputValue.ratePerHour}
                onChange={handleChangeInput}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel className={classes.globalInputLabel}>
                Rate Per Kilometer
              </InputLabel>
              <TextField
                placeholder="Rate Per Kilometer"
                variant="outlined"
                size="small"
                type="number"
                className={classes.globalInput}
                name="ratePerKm"
                value={inputValue.ratePerKm}
                onChange={handleChangeInput}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel className={classes.globalInputLabel}>
                City Commission
              </InputLabel>
              <TextField
                placeholder="City Commission"
                variant="outlined"
                size="small"
                type="number"
                className={classes.globalInput}
                name="cityCommission"
                value={inputValue.cityCommission}
                onChange={handleChangeInput}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogFooter
          type={type}
          handleCreate={handleBtn}
          handleUpdate={handleBtn}
          handleClose={handleClose}
        />
      </BootstrapDialog>
    </div>
  );
};

export default AddEditCities;
