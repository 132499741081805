import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { Navigate, useNavigate } from 'react-router-dom';
import { AdminRoutes } from '../../routes/allRoutes';
import { Avatar, Icon, ListItemAvatar, Menu, MenuItem } from '@mui/material';
import { images } from '../../assets';
import { AppContext } from '../../context';

function NavigationDrawer(props) {
    const { userData, setUserData } = useContext(AppContext);
    const drawerWidth = 240;
    const { window } = props;
    const [anchorEl, setAnchorEl] = useState(null)
    const [mobileOpen, setMobileOpen] = useState(false);
    const navigate = useNavigate()

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleDrawerNavigation = (routee) => {
        navigate(`/${routee.path}`)

    }
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        handleClose();
        localStorage.removeItem("userInfo")
        setUserData(null)
        navigate('/login')
    }
    const drawer = (
        <div>
            <Toolbar sx={{
                flexDirection: 'column',
                display: 'flex'
            }}>
                <img
                    src={images.logoImage}
                    height={73}
                    width={73}
                />
            </Toolbar>

            <Divider />
            <List>
                {AdminRoutes?.map((route) => (
                    <ListItem key={route.id} disablePadding>
                        <ListItemButton onClick={() => handleDrawerNavigation(route)}>
                            <ListItemIcon>
                                {route.icon}
                            </ListItemIcon>
                            <ListItemText primary={route.name} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>


        </div >
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                    padding: '4px',
                    bgcolor: 'white'
                }}
            >
                <Toolbar sx={{ bgcolor: '#FFF', justifyContent: 'space-between' }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon htmlColor='#000' />
                    </IconButton>
                    <Box sx={{
                        height: '40px',
                        width: '40px',
                        borderRadius: '100px'
                    }}>

                    </Box>
                    <ListItemAvatar onClick={handleMenu}>
                        <Avatar src="/broken-image.jpg" />
                    </ListItemAvatar>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: "center",
                            horizontal: "right",
                        }}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        {/* <MenuItem onClick={handleClose}>Profile</MenuItem> */}
                        <MenuItem
                            onClick={handleLogout}
                        >
                            Logout
                        </MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                <Toolbar />
                {props.children}
            </Box>
        </Box>
    );
}

NavigationDrawer.propTypes = {
    window: PropTypes.func,
};

export default NavigationDrawer;