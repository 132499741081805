import React from "react";

const EmptyRoute = () => {
    return (
        <div
            style={{
                display: "flex",
                height: "100vh",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <h1>No Route Found</h1>
        </div>
    );
};

export default EmptyRoute;
