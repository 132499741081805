import { makeStyles } from "@mui/styles";

const dynamicTableStyle = makeStyles((theme) => ({
  statusSwitchOn: {
    "&.MuiSwitch-root .MuiSwitch-switchBase": {
      color: "green",
    },
    "&.MuiSwitch-root .MuiSwitch-track": {
      background: "green",
    },
  },
  restSwitchActive: {
    // For Active Green
    "&.MuiFormControlLabel-root .MuiSwitch-root .MuiSwitch-track": {
      background: "green",
    },
    "&.MuiFormControlLabel-root .MuiSwitch-root .Mui-checked": {
      color: "green !important",
    },

    // For In Active Red
    "&.MuiFormControlLabel-root .MuiSwitch-root .MuiSwitch-switchBase": {
      color: "green",
    },
    "&.MuiFormControlLabel-root .MuiSwitch-root .MuiSwitch-switchBase .MuiSwitch-thumb":
      {
        color: "green",
      },
    "&.MuiFormControlLabel-root": {
      marginTop: "15px",
      marginLeft: "13px",
    },
  },
  restSwitchInActive: {
    // For Active Green
    "&.MuiFormControlLabel-root .MuiSwitch-root .MuiSwitch-track": {
      background: "red",
    },
    "&.MuiFormControlLabel-root .MuiSwitch-root .Mui-checked": {
      color: "red !important",
    },

    // For In Active Red
    "&.MuiFormControlLabel-root .MuiSwitch-root .MuiSwitch-switchBase": {
      color: "red",
    },
    "&.MuiFormControlLabel-root .MuiSwitch-root .MuiSwitch-switchBase .MuiSwitch-thumb":
      {
        color: "red",
      },
    "&.MuiFormControlLabel-root": {
      marginTop: "15px",
      marginLeft: "13px",
    },
  },
}));

export default dynamicTableStyle;
