import { Box, Grid, Typography } from "@mui/material";
import CustomButton from "../../../components/customButton";
import NavigationDrawer from "../../../components/navigationDrawer";
import DynamicTable from "../../../components/dynamicTable";
import { useEffect, useState } from "react";
import Loader from "../../../components/loader";
import AddEditCities from "./AddEditCities";
import SnackbarAlert from "./../../../components/snackbar/SnackbarAlert";
import DeleteHelper from "./../../../components/alert/deleteHelper";
import {
  deleteCitiesByAdmin,
  getAllCitiesByAdmin,
} from "./../../../services/cities/index";

const headerData = [
  {
    id: "cityName",
    numeric: false,
    disablePadding: true,
    label: "City Name",
  },
  {
    id: "convienceFees",
    numeric: false,
    disablePadding: true,
    label: "Convience Fees",
  },
  {
    id: "minFare",
    numeric: false,
    disablePadding: true,
    label: "Minimum Fare",
  },
  {
    id: "ratePerHour",
    numeric: false,
    disablePadding: true,
    label: "Rate Per Hour",
  },
  {
    id: "ratePerKm",
    numeric: false,
    disablePadding: true,
    label: "Rate Per Kilometer",
  },
  {
    id: "commissionFees",
    numeric: false,
    disablePadding: true,
    label: "Commission Fees",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: true,
    label: "Action",
  },
];
const displayRows = [
  "name",
  "convenceFees",
  "minfare",
  "fareHr",
  "fareKm",
  "commisionFees",
  "actions",
];

const Cities = () => {
  const [allCities, setAllCities] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogData, setDialogData] = useState(null);
  const [type, setType] = useState("add");
  const [inputValue, setInputValue] = useState({
    cityName: "",
    convienceFees: 0,
    minimumFare: 0,
    ratePerHour: 0,
    ratePerKm: 0,
    cityCommission: 0,
  });
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });

  const handleCloseSnackbar = () => {
    handleSnackbarVal(false, "", "");
  };

  const handleSnackbarVal = (open, type, message) => {
    setSnackbarProps({
      open,
      type,
      message,
    });
  };

  useEffect(() => {
    getAllCities();
  }, []);
  const getAllCities = () => {
    setIsLoading(true);
    getAllCitiesByAdmin()
      .then((resss) => {
        setIsLoading(false);
        setAllCities(resss?.data?.data);
      })
      .catch((errrrr) => {
        setIsLoading(false);
      });
  };

  const handleClickBtn = () => {
    setType("add");
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDialogData(null);
    setType("add");
    setInputValue({
      name: "",
      convienceFees: 0,
      minimumFare: 0,
      ratePerHour: 0,
      ratePerKm: 0,
    });
  };

  const handleDelete = (row) => {
    DeleteHelper(true, row._id, deleteCitiesByAdmin, getAllCities);
  };

  const openEditDialog = (row) => {
    setType("edit");
    setDialogData(row);
    setOpenDialog(true);
  };

  return (
    <NavigationDrawer>
      <Loader isloading={isLoading} />
      <SnackbarAlert
        snackbarProps={snackbarProps}
        handleCloseSnackbar={handleCloseSnackbar}
      />
      <Grid container>
        <Grid
          item
          md={12}
          xs={12}
          mb={2}
          sx={{ width: "100%", display: "flex", alignItems: "center" }}
        >
          <Typography variant="h1" sx={{ fontSize: 40 }}>
            Cities
          </Typography>
        </Grid>

        <div style={{ width: "10%", marginLeft: "auto", marginBottom: "15px" }}>
          <CustomButton
            btnLabel={"Add City"}
            height="100%"
            handlePressBtn={handleClickBtn}
          />
        </div>
        <DynamicTable
          headerData={headerData}
          bodyRows={allCities}
          displayData={displayRows}
          showEdit
          showDelete
          openEditDialog={openEditDialog}
          handleDelete={handleDelete}
        />
      </Grid>
      <AddEditCities
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        setInputValue={setInputValue}
        inputValue={inputValue}
        open={openDialog}
        setOpen={setOpenDialog}
        handleClose={handleCloseDialog}
        setDialogData={setDialogData}
        dialogData={dialogData}
        setType={setType}
        type={type}
        handleSnackbarVal={handleSnackbarVal}
        getAllCities={getAllCities}
      />
    </NavigationDrawer>
  );
};
export default Cities;
