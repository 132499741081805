import * as React from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import dynamicTableStyle from "../dynamicTable/style";

export default function CustomSwitch({
  switchLabel,
  handleSwitchChange,
  checked,
}) {
  const classes = dynamicTableStyle();
  console.log(checked, "checkedcheckedcheckedcheckedchecked");
  return (
    <FormControlLabel
      className={
        checked
          ? `${classes.restSwitchActive}`
          : `${classes.restSwitchInActive}`
      }
      control={<Switch size={"small"} />}
      checked={checked}
      label={switchLabel}
      onChange={handleSwitchChange}
    />
  );
}
