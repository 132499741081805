import { Button } from "@mui/material";
import React from "react";
import { allColors, secondaryColor } from "../../constants/colors";


const CustomButton = ({
  btnLabel,
  handlePressBtn,
  btnBgColor,
  btnTextColor,
  btnHoverColor,
  btnTextTransform,
  endIcon,
  textWeight,
  borderColor,
  variant,
  width,
  height,
  btnTextSize,
  borderRadius,
  disabled,
}) => {
  return (
    <Button
      sx={{
        borderColor: borderColor,
        width: width ? width : "100%",
        height: height ? height : "100%",
        backgroundColor: btnBgColor ? btnBgColor : allColors.blue,
        color: btnTextColor ? btnTextColor : "#fff",
        fontWeight: textWeight ? textWeight : "400",
        borderRadius: borderRadius ? borderRadius : "6px",
        fontSize: btnTextSize ? btnTextSize : "15px",
        padding: "0 15px",
        textTransform: btnTextTransform ? btnTextTransform : "capitalize",
        "&:hover": {
          backgroundColor: btnHoverColor ? btnHoverColor : secondaryColor,
          borderColor: borderColor,
        },
      }}
      onClick={handlePressBtn}
      endIcon={endIcon}
      variant={variant ? variant : ""}
      disabled={disabled ? disabled : false}
      size="small"
    >
      {btnLabel}
    </Button>
  );
};

export default CustomButton;
