import { Box, Grid, Typography } from "@mui/material";
import CustomButton from "../../../components/customButton";
import NavigationDrawer from "../../../components/navigationDrawer";
import DynamicTable from "../../../components/dynamicTable";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import { getAllTrips } from "../../../services/trips";
import Loader from "../../../components/loader";

const Trips = () => {
    const navigate = useNavigate()
    const [isloading, setIsloading] = useState(false)
    const [tripData, setTripData] = useState([])
    useEffect(() => {
        gettrips()
    }, [])
    const navigateToDetail = (row) => {
        navigate('/tripsDetails', { state: row })
    }
    const headerData = [
        {
            id: "userName",
            numeric: false,
            disablePadding: true,
            label: "User Name",
        },
        {
            id: "driverName",
            numeric: false,
            disablePadding: true,
            label: "Driver Name",
        },
        {
            id: "whereFrom",
            numeric: false,
            disablePadding: true,
            label: "Pick Up Location",
        },
        {
            id: "dropOff",
            numeric: false,
            disablePadding: true,
            label: "Drop Off Location",
        },
        {
            id: "rideCharges",
            numeric: false,
            disablePadding: true,
            label: "Ride Charges",
        },
        {
            id: "commissionStatus",
            numeric: false,
            disablePadding: true,
            label: "Commission Status",
        },
        {
            id: "commissionCharges",
            numeric: false,
            disablePadding: true,
            label: "Commission Charges",
        },
        {
            id: "vehicalType",
            numeric: false,
            disablePadding: true,
            label: "Vehical Type",
        },
        {
            id: "actions",
            numeric: false,
            disablePadding: true,
            label: "Veiw Details",
        },
    ];
    const displayRows = [
        "userName",
        "driverName",
        "whereFrom",
        "whereTo",
        "estimatedFare",
        "commissionStatus",
        "commissionCharges",
        "rideType",
        'actions',
    ];


    const gettrips = () => {
        setIsloading(true)
        getAllTrips().then(resss => {
            setIsloading(false)
            setTripData(resss?.data?.data)
        })
    }
    return (
        <NavigationDrawer>
            <Grid container>
                <Grid item md={12} xs={12} mb={2} sx={{ width: "100%", display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h1" sx={{ fontSize: 40 }}>
                        Trips
                    </Typography>
                </Grid>

                <DynamicTable
                    headerData={headerData}
                    bodyRows={tripData}
                    displayData={displayRows}
                    navigateToDetail={navigateToDetail}
                    showView={true}
                    width={1500}
                />
            </Grid>
            <Loader isloading={isloading} />
        </NavigationDrawer>
    )
}
export default Trips;