import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { images } from "../../../assets";
import Loader from "../../../components/loader";
import { allColors } from "../../../constants/colors";
import { getAllSettings } from "../../../services/settings";

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  const [settings, setSettings] = useState(null);
  const [isloading, setIsloading] = useState(false);

  useEffect(() => {
    getSettings();
  }, []);
  const getSettings = () => {
    setIsloading(true);
    getAllSettings()
      .then((resss) => {
        setSettings(resss.data.data);
        setIsloading(false);
      })
      .catch((errrr) => {
        setIsloading(false);
      });
  };
  return (
    <div
      style={{
        height: "100vh",
        background: allColors.backgroundColor,
      }}
    >
      <Loader isloading={isloading} />
      <Grid container>
        <Grid item xs={12} md={5}>
          <img
            style={{
              width: "100%",
              height: "100vh",
              verticalAlign: "middle",
              borderStyle: "none",
            }}
            src={images.background}
          />
        </Grid>
        <Grid item xs={12} md={7}>
          <Grid
            container
            mt={2}
            alignItems={"center"}
            justifyContent={"center"}
            style={{ height: "95vh", overflow: "auto" }}
          >
            <Grid item xs={10}>
              <div style={{ textAlign: "center" }}>
                <img src={images.logoImage} height={"20%"} width={"20%"} />
              </div>

              <Typography variant="h1" sx={{ fontSize: 35 }} mb={2}>
                Privacy Policy
              </Typography>

              {settings && (
                <div
                  dangerouslySetInnerHTML={{ __html: settings?.privacyPolicy }}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default PrivacyPolicy;
