import React from "react";
import User from "../../containers/app/user";
import Driver from "../../containers/app/driver";
import Trips from "../../containers/app/trips";
import Settings from "../../containers/app/settings";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import LocalTaxiIcon from "@mui/icons-material/LocalTaxi";
import ModeOfTravelIcon from "@mui/icons-material/ModeOfTravel";
import Cities from "../../containers/app/cities";
import Map from "../../containers/app/map";
import FmdGoodIcon from '@mui/icons-material/FmdGood';

const AdminRoutes = [
  {
    id: 1,
    name: "Users",
    component: <User />,
    exact: "exact",
    path: "",
    icon: <AccountCircleIcon />,
  },
  {
    id: 2,
    name: "Drivers",
    component: <Driver />,
    exact: "exact",
    path: "drivers",
    icon: <LocalTaxiIcon />,
  },
  {
    id: 3,
    name: "Trips",
    component: <Trips />,
    exact: "exact",
    path: "trips",
    icon: <ModeOfTravelIcon />,
  },
  {
    id: 4,
    name: "Cities",
    component: <Cities />,
    exact: "exact",
    path: "cities",
    icon: <LocationCityIcon />,
  },
  {
    id: 5,
    name: "Settings",
    component: <Settings />,
    exact: "exact",
    path: "settings",
    icon: <SettingsOutlinedIcon />,
  },
  {
    id: 6,
    name: "Map",
    component: <Map />,
    exact: "exact",
    path: "map",
    icon: <FmdGoodIcon />,
  },
];

export { AdminRoutes };
