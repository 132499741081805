import { Box, Grid, Modal, Typography } from "@mui/material";
import NavigationDrawer from "../../../components/navigationDrawer";
import DynamicTable from "../../../components/dynamicTable";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getAllDriver, updateDriverStatus } from "../../../services/drivers";
import Swal from "sweetalert2";
import Loader from "../../../components/loader";

const Driver = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [detailImage, setDetailImage] = useState("");
  const [driversData, setDriversData] = useState([]);
  const [isloading, setIsloading] = useState(false);

  useEffect(() => {
    getDrivers();
  }, []);

  const getDrivers = async () => {
    try {
      setIsloading(true);
      const resss = await getAllDriver();
      setIsloading(false);
      if (resss.status == 200 || resss.status == 201) {
        setDriversData(resss?.data?.data);
      }
    } catch (error) {
      setIsloading(false);
      console.log(error, "errorerror");
    }
  };

  const headerData = [
    {
      id: "userName",
      numeric: false,
      disablePadding: true,
      label: "Name",
    },
    {
      id: "userEmail",
      numeric: false,
      disablePadding: true,
      label: "Email",
    },
    {
      id: "userContact",
      numeric: false,
      disablePadding: true,
      label: "Phone Number",
    },
    {
      id: "vehicleType",
      numeric: false,
      disablePadding: true,
      label: "Vehicle Type ",
    },
    {
      id: "showImageLicenceF",
      numeric: false,
      disablePadding: true,
      label: "Licence Front",
    },
    {
      id: "showImageLicenceB",
      numeric: false,
      disablePadding: true,
      label: "Licence Back",
    },
    {
      id: "showImageInsurance",
      numeric: false,
      disablePadding: true,
      label: "Vehicle Insurance",
    },
    {
      id: "showImageRoadWorthy",
      numeric: false,
      disablePadding: true,
      label: "Road Worthy",
    },
    {
      id: "switch",
      numeric: false,
      disablePadding: true,
      label: "Status",
    },
  ];
  const displayRows = [
    "name",
    "email",
    "phoneNumber",
    "vehicleType",
    "showImageLicenceF",
    "showImageLicenceB",
    "showImageInsurance",
    "showImageRoadWorthy",
    "switch",
  ];

  const handleUpdateDriver = (event, row) => {
    let payload = {
      status: !row.status ? true : false,
    };
    setIsloading(true);
    updateDriverStatus(row._id, payload)
      .then((resss) => {
        setIsloading(false);
        if (resss?.data?.status == "ok") {
          getDrivers();
          Swal.fire({
            text: "Status Update succesfuly",
            icon: "success",
          });
        }
      })
      .catch((err) => {
        setIsloading(false);
        console.log(err, "cnsdjsdfdfsdbh");
      });
  };

  const handleShowImageDetaile = (row) => {
    setDetailImage(row);
    if (row) {
      setOpen(true);
    }
  };

  const handleClose = () => setOpen(false);
  return (
    <>
      <NavigationDrawer>
        <Grid container>
          <Grid
            item
            md={12}
            xs={12}
            mb={2}
            sx={{ width: "100%", display: "flex", alignItems: "center" }}
          >
            <Typography variant="h1" sx={{ fontSize: 40 }}>
              Drivers
            </Typography>
          </Grid>
          <DynamicTable
            headerData={headerData}
            bodyRows={driversData}
            displayData={displayRows}
            handleSwitchUpdate={handleUpdateDriver}
            handleShowImageDetaile={handleShowImageDetaile}
          />
        </Grid>
        <Loader isloading={isloading} />
      </NavigationDrawer>
      <Modal
        open={open}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            height: 200,
            width: 350,
            borderRadius: "12px",
            bgcolor: "#FFF",
            overflow: "hidden",
          }}
        >
          <img src={detailImage} height={"100%"} width={"100%"} />
        </Box>
      </Modal>
    </>
  );
};
export default Driver;
