import React, { useEffect, useState } from "react";
import NavigationDrawer from "../../../components/navigationDrawer";
import { Box, Typography } from "@mui/material";
import Loader from "../../../components/loader";
import { getAllDriver } from "../../../services/drivers";
import SnackbarAlert from "../../../components/snackbar/SnackbarAlert";
import GoogleMapReact from "google-map-react";
import { images } from "../../../assets";

function Map() {
  const [isLoading, setIsLoading] = useState(false);
  const [allDrivers, setAllDrivers] = useState([]);
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });

  const [location, setLocation] = useState({
    latitude: "",
    longitude: "",
  });

  const pakistanCenter = {
    latitude: 30.3753,
    longitude: 69.3451,
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setLocation({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      });
    });
  }, []);

  console.log(location, "njnjdasdbjadasdjkadasdjk");

  const handleCloseSnackbar = () => {
    handleSnackbarVal(false, "", "");
  };

  const handleSnackbarVal = (open, type, message) => {
    setSnackbarProps({
      open,
      type,
      message,
    });
  };

  useEffect(() => {
    fetchAllDrivers();
  }, []);

  const fetchAllDrivers = () => {
    setIsLoading(true);
    getAllDriver()
      .then((resss) => {
        setIsLoading(false);
        if (resss?.data?.status === "ok") {
          let data = resss?.data?.data;
          console.log(data, "datadatadata");
          setAllDrivers(data);
        } else {
          handleSnackbarVal(
            true,
            "error",
            "Something went wrong while fetching drivers location"
          );
        }
      })
      .catch((err) => {
        setIsLoading(false);
        handleSnackbarVal(
          true,
          "error",
          "Something went wrong while fetching drivers location"
        );
      });
  };

  const renderPicture = (type) => {
    if (type === "car") {
      return images.carImage;
    } else if (type === "bike") {
      return images.bykeImage;
    } else if (type === "taxi") {
      return images.taxiImage;
    } else {
      return images.rakshaw;
    }
  };
  const DriverMarker = ({ name, vehicleType, index }) => {
    const [isHovered, setIsHovered] = useState(false);
    const offset = index * 10; // Adjust the offset based on your needs

    return (
      <Box
        style={{
          position: "absolute",
          transform: `translate(-50%, -50%) translate(${offset}px, ${offset}px)`,
          cursor: "pointer",
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <img
          src={renderPicture(vehicleType)}
          alt={vehicleType}
          style={{ width: "30px", height: "30px" }}
        />

        {isHovered && (
          <Box
            style={{
              position: "absolute",
              top: "-40px",
              left: "50%",
              transform: "translateX(-50%)",
              background: "white",
              padding: "5px",
              borderRadius: "5px",
              boxShadow: "0 0 5px rgba(0, 0, 0, 0.3)",
              textAlign: "center",
            }}
          >
            <img
              src={renderPicture(vehicleType)}
              alt={vehicleType}
              style={{ width: "40px", height: "40px" }}
            />
            <Typography variant="h2" fontSize={13} fontWeight="bold">
              {name}
            </Typography>
          </Box>
        )}
      </Box>
    );
  };

  return (
    <NavigationDrawer>
      <Loader isloading={isLoading} />
      <SnackbarAlert
        snackbarProps={snackbarProps}
        handleCloseSnackbar={handleCloseSnackbar}
      />
      <Typography variant="h1" fontSize={25} fontWeight={"bold"}>
        All Driver's Location
      </Typography>

      <Box
        style={{ height: "500px", width: "100%" }}
        border={"1px solid gray"}
        marginTop={"20px"}
      >
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyDgm3VBE9YF9fIYqHU6Cue4OfJBtJBlxj4",
            language: "en",
          }}
          //   defaultCenter={{
          //     lat: location?.latitude || 0,
          //     lng: location?.longitude || 0,
          //   }}
          defaultCenter={{
            lat: 0,
            lng: 0,
          }}
          defaultZoom={4}
        >
          {allDrivers?.map((driver, index) => {
            return (
              driver.currentLat &&
              driver.currentLat && (
                <DriverMarker
                  key={driver._id}
                  lat={parseFloat(driver.currentLat)}
                  lng={parseFloat(driver.currentLat)}
                  name={driver.name}
                  vehicleType={driver.vehicleType}
                  index={index}
                />
              )
            );
          })}
        </GoogleMapReact>
      </Box>
    </NavigationDrawer>
  );
}

export default Map;
