export const requestType = {
  POST: "post",
  GET: "get",
  PUT: "put",
  DELETE: "delete",
};

export const endPoints = {
  // auth
  loginAdmin: "admin/login",

  //getusers
  getAllUsers: "getAllUsers",
  updateUserStatus: "user/update",

  //getDrivers
  getAllDriver: "getAllDrivers",
  updateDriverStatus: "driver/update",

  //get Trips
  getTrips: "getAllTrips",

  //get Trips
  getAllCities: "admin/getAllCities",
  createCities: "admin/createCities",
  updateCity: "admin/updateCity",
  deleteCity: "admin/deleteCity",

  //get settings
  getSettings: "admin/fetchSettings",
  updateSettings: "admin/updateSettings",
};
