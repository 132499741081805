import React, { useContext, useEffect } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Route, Routes } from "react-router-dom";
import Login from "./containers/auth/login";
import ForgotPassword from "./containers/auth/forgotPassword";
import { AdminRoutes } from "./routes/allRoutes";
import TripsDetails from "./containers/app/trips/tripDetails";
// import { AppContext } from './context';
import AppProvider from "./context";
import EmptyRoute from "./containers/app/404";
import { ProtectedLayout } from "./components/protectedLayout";
import Map from "./containers/app/map";
import PrivacyPolicy from "./containers/app/privacyPolicy";

function App() {
  // const { setUserData } = useContext(AppContext);
  const theme = createTheme({
    typography: {
      fontFamily: ["Inter", "Lato", "sans-serif"].join(","),
    },
    button: {
      fontFamily: ["Inter", "Lato", "sans-serif"].join(","),
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <AppProvider>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/map" element={<Map />} />
          <Route path="/" element={<ProtectedLayout />}>
            <Route path="/tripsDetails" element={<TripsDetails />} />
            {AdminRoutes.map(({ component: Component, path, exact, id }) => {
              return (
                <Route key={id} path={path} exact={exact} element={Component} />
              );
            })}
          </Route>

          <Route path="*" element={<EmptyRoute />} />
        </Routes>
      </AppProvider>
    </ThemeProvider>
  );
}

export default App;
