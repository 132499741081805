import { Grid, Typography } from "@mui/material";
import NavigationDrawer from "../../../components/navigationDrawer";
import { useLocation } from "react-router-dom";
import TextInput from "../../../components/textInput";
import { useState } from "react";

const TripsDetails = () => {
    const state = useLocation()
    const [tripData, setTripData] = useState(state?.state)
    return (
        <NavigationDrawer>
            <Grid container spacing={2}>
                <Grid item md={12} >
                    <Typography sx={{ fontSize: 30, fontWeight: 700 }}>
                        Trip Details
                    </Typography>
                </Grid>
                <Grid item md={4} xs={12}>
                    <Typography sx={{ fontSize: 15, fontWeight: 700, my: 1 }}>
                        Passenger Name
                    </Typography>
                    <TextInput
                        disabled
                        value={tripData?.userDetails?.name}
                    />
                </Grid>
                <Grid item md={4} xs={12}>
                    <Typography sx={{ fontSize: 15, fontWeight: 700, my: 1 }}>
                        Driver Name
                    </Typography>
                    <TextInput
                        disabled
                        value={tripData?.driverDetails?.name}
                    />
                </Grid>
                <Grid item md={4} xs={12}>
                    <Typography sx={{ fontSize: 15, fontWeight: 700, my: 1 }}>
                        Commission Status
                    </Typography>
                    <TextInput
                        disabled
                        value={tripData?.commissionStatus ? 'Paid' : 'UnPaid'}
                    />
                </Grid>
                <Grid item md={4} xs={12}>
                    <Typography sx={{ fontSize: 15, fontWeight: 700, my: 1 }}>
                        Commission Charges
                    </Typography>
                    <TextInput
                        disabled
                        value={tripData?.estimatedFare * 0.10}
                    />
                </Grid>
                <Grid item md={4} xs={12}>
                    <Typography sx={{ fontSize: 15, fontWeight: 700, my: 1 }}>
                        Ride Charges
                    </Typography>
                    <TextInput
                        disabled
                        value={tripData?.estimatedFare}
                    />
                </Grid>
                <Grid item md={4} xs={12}>
                    <Typography sx={{ fontSize: 15, fontWeight: 700, my: 1 }}>
                        Vehical Type
                    </Typography>
                    <TextInput
                        disabled
                        value={tripData?.rideType}
                    />
                </Grid>
                <Grid item md={4} xs={12}>
                    <Typography sx={{ fontSize: 15, fontWeight: 700, my: 1 }}>
                        Driver Contact
                    </Typography>
                    <TextInput
                        disabled
                        value={tripData?.driverDetails?.phoneNumber}
                    />
                </Grid>
                <Grid item md={4} xs={12}>
                    <Typography sx={{ fontSize: 15, fontWeight: 700, my: 1 }}>
                        Passenger Contact
                    </Typography>
                    <TextInput
                        disabled
                        value={tripData?.userDetails?.phoneNumber}
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <Typography sx={{ fontSize: 15, fontWeight: 700, my: 1 }}>
                        Pick Up Location
                    </Typography>
                    <TextInput
                        disabled
                        multiline={true}
                        value={tripData?.whereFrom}
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <Typography sx={{ fontSize: 15, fontWeight: 700, my: 1 }}>
                        Drop Off Location
                    </Typography>
                    <TextInput
                        disabled
                        multiline={true}
                        value={tripData?.whereTo}
                    />
                </Grid>

            </Grid>
        </NavigationDrawer>
    )
}
export default TripsDetails;