import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import dynamicTableStyle from "./style";
import { Delete, Edit, RemoveRedEye } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import { primaryColor } from "../../constants/colors";
import CustomSwitch from "../switch";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { headerData, order, orderBy, onRequestSort, classes } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow className={classes.headerRowsStyle}>
        {headerData?.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
            style={{
              color: "#212529",
              fontWeight: "600",
            }}
          >
            {headCell.label !== "Action" ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              <>
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </Box>
                ) : null}
              </>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function DynamicTable({
  headerData,
  bodyRows,
  displayData,
  width,
  showView,
  navigateToDetail,
  showEdit,
  handleSwitchUpdate,
  openEditDialog,
  showDelete,
  handleShowImageDetaile,
  handleDelete,
}) {
  const classes = dynamicTableStyle();
  const navigate = useNavigate();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("Name");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = bodyRows?.map((n) => n.Name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <Box sx={{ width: "100%", boxShadow: "0 0.2rem 1rem rgb(0 0 0 / 12%)" }}>
      <TableContainer>
        <Table sx={{ minWidth: width ? width : 850 }} size="md" stickyHeader>
          <EnhancedTableHead
            numSelected={selected?.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={bodyRows?.length}
            headerData={headerData}
            classes={classes}
          />
          <TableBody>
            {stableSort(bodyRows, getComparator(order, orderBy))
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((row, index) => {
                const isItemSelected = isSelected(row.Name);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    padding="normal"
                    className={classes.tableRowsStyle}
                    sx={{
                      "& .MuiTableCell-root ": {
                        backgroundColor: "#fff",
                      },
                    }}
                  >
                    {displayData?.map((val, i) => {
                      return (
                        <React.Fragment key={val}>
                          {val == "switch" ? (
                            <TableCell>
                              <CustomSwitch
                                checked={row.status ? true : false}
                                handleSwitchChange={(event) =>
                                  handleSwitchUpdate(event, row)
                                }
                              />
                            </TableCell>
                          ) : val == "commissionStatus" ? (
                            <TableCell>
                              <Typography variant="h1" sx={{ fontSize: 13 }}>{row.commissionStatus ? "Paid" : "UnPaid"}</Typography>
                            </TableCell>
                          ) : val == "commissionCharges" ? (
                            <TableCell>
                              <Typography variant="h1" sx={{ fontSize: 13 }}>{row.estimatedFare * 0.10}</Typography>
                            </TableCell>
                          ) : val == "driverName" ? (
                            <TableCell>
                              <Typography variant="h1" sx={{ fontSize: 13 }}>{row?.driverDetails?.name}</Typography>
                            </TableCell>
                          ) : val == "userName" ? (
                            <TableCell>
                              <Typography variant="h1" sx={{ fontSize: 13 }}>{row?.userDetails?.name}</Typography>
                            </TableCell>
                          ) : val == "showImageLicenceF" ? (
                            <TableCell>
                              {row?.licenceFront ?
                                <div
                                  onClick={() => handleShowImageDetaile(row?.licenceFront)}>
                                  <img src={row?.licenceFront} height={40} width={40} />
                                </div> :
                                <div>
                                  No Photo Uploded
                                </div>}
                            </TableCell>
                          ) : val == "showImageLicenceB" ? (
                            <TableCell>
                              {row?.licenceBack ?
                                <div
                                  onClick={() => handleShowImageDetaile(row?.licenceBack)}>
                                  <img src={row?.licenceBack} height={40} width={40} />
                                </div> :
                                <div>
                                  No Photo Uploded
                                </div>}
                            </TableCell>
                          ) : val == "showImageInsurance" ? (
                            <TableCell>
                              {row?.vehicleInsurance ?
                                <div
                                  onClick={() => handleShowImageDetaile(row?.vehicleInsurance)}>
                                  <img src={row?.vehicleInsurance} height={40} width={40} />
                                </div> :
                                <div>
                                  No Photo Uploded
                                </div>}
                            </TableCell>
                          ) : val == "showImageRoadWorthy" ? (
                            <TableCell>
                              {row?.roadWorthy ?
                                <div
                                  onClick={() => handleShowImageDetaile(row?.roadWorthy)}>
                                  <img src={row?.roadWorthy} height={40} width={40} />
                                </div> :
                                <div>
                                  No Photo Uploded
                                </div>}
                            </TableCell>
                          ) : val !== 'actions' ? (
                            <TableCell padding='normal'>{row[val]}</TableCell>
                          ) : (
                            <TableCell padding='normal'>
                              {showView && (
                                <RemoveRedEye
                                  style={{
                                    color: primaryColor,
                                    fontSize: '20px',
                                    cursor: 'pointer',
                                    marginRight: '10px',
                                  }}
                                  onClick={() => navigateToDetail(row)}
                                />
                              )}
                              {showEdit && (
                                <Edit
                                  style={{
                                    marginRight: '10px',
                                    color: primaryColor,
                                    fontSize: '20px',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => openEditDialog(row, index)}
                                />
                              )}
                              {showDelete && (
                                <Delete
                                  style={{
                                    color: 'red',
                                    fontSize: '20px',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => handleDelete(row, index)}
                                />
                              )}
                            </TableCell>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={bodyRows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{ backgroundColor: "#fff" }}
      />
    </Box >
  );
}