import Api from "../index";
import { endPoints, requestType } from "../../constants/variables";

export const getAllCitiesByAdmin = () => {
  return Api(endPoints.getAllCities, null, requestType.GET);
};

export const createCitiesByAdmin = (params) => {
  return Api(endPoints.createCities, params, requestType.POST);
};

export const updateCitiesByAdmin = (id, params) => {
  return Api(`${endPoints.updateCity}/${id}`, params, requestType.PUT);
};

export const deleteCitiesByAdmin = (id) => {
  return Api(`${endPoints.deleteCity}/${id}`, null, requestType.DELETE);
};
