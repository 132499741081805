import React, { useContext, useState } from "react";
import { Typography, Grid, Container } from "@mui/material";
import { Navigate, useNavigate } from "react-router-dom";
import { allColors } from "../../../constants/colors";
import TextInput from "../../../components/textInput";
import CustomButton from "../../../components/customButton";
import { adminLogin } from "../../../services/auth";
import Swal from "sweetalert2";
import { images } from "../../../assets";
import { AppContext } from "../../../context";
import Loader from "../../../components/loader";

const Login = () => {
  const navigate = useNavigate();
  const { userData, setUserData } = useContext(AppContext);
  const [isloading, setIsloading] = useState(false);
  const [inputValues, setInputValues] = useState({
    userName: "",
    password: "",
  });
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };

  console.log(userData, "userDatauserDatauserDatauserData");

  const handleLogin = () => {
    let payload = {
      email: inputValues.userName,
      password: inputValues.password,
    };
    setIsloading(true);
    adminLogin(payload)
      .then((resss) => {
        setIsloading(false);
        if (resss?.data?.status == "ok") {
          const userInfo = resss?.data?.data;
          localStorage.setItem("userInfo", JSON.stringify(userInfo));
          setUserData(true);
        } else {
          setUserData(false);
          Swal.fire({
            title: resss?.data?.error,
            icon: "warning",
          });
        }
      })
      .catch((err) => {
        console.log(err, "errerrerrerrerr");
      });
  };

  if (userData) {
    return <Navigate to="/" replace={true} />;
  }
  return (
    <div style={{ height: "100vh", background: allColors.backgroundColor }}>
      <Grid container>
        <Grid item xs={12} md={6.5}>
          <img
            style={{
              width: "100%",
              height: "100vh",
              verticalAlign: "middle",
              borderStyle: "none",
            }}
            src={images.background}
          />
        </Grid>
        <Grid item xs={12} md={5.5}>
          <Container
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              height: "100vh",
              maxWidth: "390px !important",
            }}
          >
            <div
              style={{
                height: "130px",
                width: "130px",
              }}
            >
              <img src={images.logoImage} height={"100%"} width={"100%"} />
            </div>
            <p
              style={{
                fontSize: "35px",
                textAlign: "center",
                marginBottom: "0px",
              }}
            >
              Car To Go Portal
            </p>
            <p
              style={{
                fontSize: "12px",
                textAlign: "center",
                marginTop: -"20px",
              }}
            >
              Welcome back! Please login to your account.
            </p>
            <Grid container mt={1}>
              <Grid item xs={12} md={12}>
                <TextInput
                  type={"text"}
                  placeholder={"Email"}
                  name={"userName"}
                  value={inputValues.userName}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} md={12} mt={1}>
                <TextInput
                  placeholder={"Password"}
                  type={"password"}
                  name={"password"}
                  value={inputValues.password}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
            <Typography
              variant={"body2"}
              fontSize={12}
              color={allColors.red}
              margin={"10px 0px 10px auto"}
              sx={{ cursor: "pointer", textDecoration: "underline" }}
              // onClick={() => navigate("/forgotPassword")}
              mb={2}
            >
              {/* Forgot Password */}
            </Typography>
            <CustomButton
              btnLabel={"Login"}
              height={45}
              handlePressBtn={handleLogin}
              btnBgColor={allColors.blue}
            />
          </Container>
        </Grid>
      </Grid>
      <Loader isloading={isloading} />
    </div>
  );
};

export default Login;
