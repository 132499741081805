import React, { useContext, useState } from "react";
import { Typography, Grid, Container } from "@mui/material";
import CustomButton from "../../../components/customButton";
import TextInput from "../../../components/textInput";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Navigate, useNavigate } from "react-router-dom";
import { allColors } from "../../../constants/colors";
import { images } from "../../../assets";
import { AppContext } from "../../../context";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { userData, } = useContext(AppContext);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [isloading, setIsLoading] = useState(false);
  const [inputValues, setInputValues] = useState({
    username: "",
    email: "",
    newPassowrd: "",
  });

  const handleChangeInput = (event) => {
    const { name, value } = event.target;
    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };

  console.log(userData, "userDatauserDatauserDatauserData")

  if (userData) {
    return <Navigate to="/" replace={true} />;
  }

  return (
    <div style={{ height: "100vh", background: allColors.backgroundColor }}>
      <Grid container>
        <Grid item xs={12} md={6.5}>
          <img
            style={{
              width: "100%",
              height: "100vh",
              verticalAlign: "middle",
              borderStyle: "none",
            }}
            src={images.background}
          />
        </Grid>
        <Grid item xs={12} md={5.5}>
          <Container
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              height: "100vh",
              maxWidth: "390px !important",
            }}
          >
            <div style={{
              height: '130px',
              width: '130px',
            }}>
              <img src={images.logoImage} height={'100%'} width={"100%"} />
            </div>
            <p
              style={{
                fontSize: "35px",
                textAlign: "center",
                marginBottom: "0px",
              }}
            >
              Car To Go Portal
            </p>
            <p
              style={{
                fontSize: "12px",
                textAlign: "center",
                marginTop: -"20px",
              }}
            >
              Enter your email and username to reset Password
            </p>

            <Grid container mt={1}>
              <Grid item xs={12} md={12}>
                <TextInput
                  type={"text"}
                  placeholder={"Username"}
                  name={"username"}
                  value={inputValues.username}
                  onChange={handleChangeInput}
                />
              </Grid>
              <Grid item xs={12} md={12} marginY={2}>
                <TextInput
                  type={"email"}
                  placeholder={"Email"}
                  name={"email"}
                  value={inputValues.email}
                  onChange={handleChangeInput}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextInput
                  type={"password"}
                  placeholder={"Password"}
                  name={"newPassowrd"}
                  value={inputValues.newPassowrd}
                  onChange={handleChangeInput}
                />
              </Grid>
            </Grid>
            <Typography
              variant={"body2"}
              fontSize={13}
              fontWeight={600}
              color={allColors.black}
              margin={"10px auto 10px 0px"}
              sx={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
              onClick={() => navigate("/login")}
            >
              <KeyboardBackspaceIcon />
              Go Back
            </Typography>
            <CustomButton
              btnLabel={"Reset Password"}
              height={45}
              // handlePressBtn={handleForgotPassword}
              btnBgColor={allColors.blue}
            />
          </Container>
        </Grid>
      </Grid>
    </div>
  );
};

export default ForgotPassword;
