import { makeStyles } from "@mui/styles";
import { primaryColor, secondaryColor } from "../constants/colors";

const useStyles = makeStyles((theme) => ({
  profileHeader: {
    "&.MuiListItem-root .MuiListItemAvatar-root .MuiAvatar-root": {
      height: "38px",
      width: "38px",
    },
    "&.MuiListItem-root .MuiListItemText-root .MuiListItemText-primary": {
      fontSize: "13px !important",
      color: "black",
    },
  },

  globalInput: {
    width: "100%",
    "&.MuiFormControl-root .MuiInputBase-root .MuiInputBase-input": {
      padding: "12px 10px !important",
      fontSize: "14px !important",
    },
    "&.MuiFormControl-root .MuiInputBase-root .MuiInputBase-input::placeholder":
    {
      fontSize: "14px !important",
    },
  },

  globalBtnStyle: {
    textTransform: "none !important",
    background: `${primaryColor} !important`,
    boxShadow: "none !important",
    "&:hover": {
      background: `${secondaryColor} !important`,
    },
  },

  globalInputLabel: {
    fontSize: "15px !important",
    margin: "5px 0",
    fontWeight: "700 !important",
    color: "black !important",

  },
  globalInputLabel1: {
    fontSize: "13px !important",
    margin: "10px 0",
    fontWeight: "700 !important",
    color: "black !important",

  },
  menuItemStyle: {
    fontSize: "12px !important",
  },
}));

export default useStyles;
