export const allColors = {
  blue: "#3978e2",
  black: "#000000",
  red: "red",
  lightGray: "#d3d3d3",
  backgroundColor: "#f5f6f8",
  navyBlue: "#43425d"
};
export const primaryColor = "#3978e2";

export const secondaryColor = "#000000";