import { Link, Navigate, useOutlet } from "react-router-dom";

export const ProtectedLayout = () => {
  const userData = localStorage.getItem('userInfo')
  const user = JSON.parse(userData)
  const outlet = useOutlet();
  if (!user) {
    return <Navigate to="/login" />;
  }
  return <div>{outlet}</div>;
};
