import React, { createContext, useState, memo, useEffect } from "react";

export const AppContext = createContext();
const AppProvider = (props) => {
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        const userDaaattaaa = JSON.parse(localStorage.getItem('userInfo'))
        setUserData(userDaaattaaa)
    }, [])


    return (
        <AppContext.Provider
            value={{
                userData,
                setUserData,
            }}
        >
            {props.children}
        </AppContext.Provider>
    );
};

export default memo(AppProvider);
