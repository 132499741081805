import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import NavigationDrawer from "../../../components/navigationDrawer";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import TextInput from "../../../components/textInput";
import CustomButton from "../../../components/customButton";
import { getAllSettings, updateSettings } from "../../../services/settings";
import Swal from "sweetalert2";
import draftToHtml from "draftjs-to-html";
import Loader from "../../../components/loader";

const Settings = () => {
  const [selectedVehical, setSelectedVehical] = useState("");
  const [settingsData, setSettingsData] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [commissionVal, setCommissionVal] = useState("");
  const [termsCondition, setTermsCondition] = useState(() =>
    EditorState.createEmpty()
  );
  const onTermsChange = (data) => {
    setTermsCondition(data);
  };
  const [about, setAbout] = useState(() => EditorState.createEmpty());
  const [privacyPolicy, setPrivacyPolicy] = useState(() =>
    EditorState.createEmpty()
  );

  useEffect(() => {
    getSettings();
  }, []);
  const getSettings = () => {
    setIsloading(true);
    getAllSettings().then((resss) => {
      console.log(resss.data.data, "resssresssresssresssresss");
      setIsloading(false);
      setSettingsData(resss?.data?.data);
      setCommissionVal(resss?.data?.data?.commision);

      // About US
      const contentState = ContentState.createFromBlockArray(
        convertFromHTML(resss?.data?.data?.aboutus)
      );
      setAbout(EditorState.createWithContent(contentState));

      // Terms & Conditions
      const termsConditionState = ContentState.createFromBlockArray(
        convertFromHTML(resss?.data?.data?.terms)
      );
      setTermsCondition(EditorState.createWithContent(termsConditionState));

      // Privacy Policy
      const privacyPolicyState = ContentState.createFromBlockArray(
        convertFromHTML(
          resss?.data?.data?.privacyPolicy
            ? resss?.data?.data?.privacyPolicy
            : ""
        )
      );
      setPrivacyPolicy(EditorState.createWithContent(privacyPolicyState));
    });
  };
  const allRideType = [
    { id: 1, vehicalName: "Car", value: "carDetails" },
    { id: 2, vehicalName: "Bike", value: "bikeDetails" },
    { id: 3, vehicalName: "Taxi", value: "taxiDetails" },
    { id: 4, vehicalName: "Paragi ", value: "paragiaDetails" },
  ];

  const onAboutChange = (data) => {
    setAbout(data);
  };

  const onPrivacyPolicyChange = (data) => {
    setPrivacyPolicy(data);
  };

  const onChangeInput = (event) => {
    const { name, value } = event.target;
    console.log(name, value, "VALUEEE");
    let miniFareee = (settingsData[selectedVehical].minfare =
      name == "minFare" ? value : settingsData[selectedVehical].minfare);
    let fareeekmm = (settingsData[selectedVehical].fareKm =
      name == "FarePerKm" ? value : settingsData[selectedVehical].fareKm);
    let fareeehrr = (settingsData[selectedVehical].fareHr =
      name == "FarePerHr" ? value : settingsData[selectedVehical].fareHr);
    let convenceeFeesss = (settingsData[selectedVehical].convenceFees =
      name == "ConvienceFees"
        ? value
        : settingsData[selectedVehical].convenceFees);

    setSettingsData({
      ...settingsData,
    });
  };

  const handlePress = () => {
    const aboutUsContent = draftToHtml(convertToRaw(about.getCurrentContent()));
    const termsContent = draftToHtml(
      convertToRaw(termsCondition.getCurrentContent())
    );
    const privacyContent = draftToHtml(
      convertToRaw(privacyPolicy.getCurrentContent())
    );

    let finallllObjjj = {
      ...settingsData,
      aboutus: aboutUsContent,
      terms: termsContent,
      privacyPolicy: privacyContent,
      commision: commissionVal,
    };
    updateSettings(settingsData?._id, finallllObjjj).then((resss) => {
      getSettings();
      Swal.fire({
        title: "Settings Updated Successfuly",
        icon: "success",
      });
    });
  };

  return (
    <NavigationDrawer>
      <Grid container>
        <Grid
          item
          md={12}
          xs={12}
          mb={2}
          sx={{ width: "100%", display: "flex", alignItems: "center" }}
        >
          <Typography variant="h1" sx={{ fontSize: 40 }}>
            Settings
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          xs={12}
          mb={2}
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h1"
            sx={{ fontSize: 30, fontWeight: 700, padding: "20px 0" }}
          >
            Terms And Conditions
          </Typography>
          <div style={{ border: "1px solid #0000003d", padding: "10px" }}>
            <Editor
              editorState={termsCondition}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={onTermsChange}
              toolbar={{
                options: [
                  "inline",
                  "blockType",
                  "fontSize",
                  "fontFamily",
                  "list",
                  "textAlign",
                  "history",
                  "colorPicker",
                  // 'link',
                ],
                inline: { inDropdown: true },
                list: { inDropdown: true },
                textAlign: { inDropdown: true },
                link: { inDropdown: true },
                history: { inDropdown: true },
              }}
            />
          </div>
        </Grid>
        <Grid
          item
          md={12}
          xs={12}
          mb={2}
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h1"
            sx={{ fontSize: 30, fontWeight: 700, padding: "20px 0" }}
          >
            About
          </Typography>
          <div style={{ border: "1px solid #0000003d", padding: "10px" }}>
            <Editor
              editorState={about}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={onAboutChange}
              toolbar={{
                options: [
                  "inline",
                  "blockType",
                  "fontSize",
                  "fontFamily",
                  "list",
                  "textAlign",
                  "history",
                  "colorPicker",
                  // 'link',
                ],
                inline: { inDropdown: true },
                list: { inDropdown: true },
                textAlign: { inDropdown: true },
                link: { inDropdown: true },
                history: { inDropdown: true },
              }}
            />
          </div>
        </Grid>
        <Grid
          item
          md={12}
          xs={12}
          mb={2}
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h1"
            sx={{ fontSize: 30, fontWeight: 700, padding: "20px 0" }}
          >
            Privacy Policy
          </Typography>
          <div style={{ border: "1px solid #0000003d", padding: "10px" }}>
            <Editor
              editorState={privacyPolicy}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={onPrivacyPolicyChange}
              toolbar={{
                options: [
                  "inline",
                  "blockType",
                  "fontSize",
                  "fontFamily",
                  "list",
                  "textAlign",
                  "history",
                  "colorPicker",
                  // 'link',
                ],
                inline: { inDropdown: true },
                list: { inDropdown: true },
                textAlign: { inDropdown: true },
                link: { inDropdown: true },
                history: { inDropdown: true },
              }}
            />
          </div>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <Typography
              variant="h1"
              sx={{ fontSize: 30, fontWeight: 700, padding: "20px 0" }}
            >
              Select Vehicle Estimated Fare
            </Typography>
          </Grid>
          <Grid item md={4} xs={12}>
            <Typography variant="h3" fontSize={15} fontWeight={700} py={1}>
              Select Vehicle Type
            </Typography>
            <FormControl fullWidth>
              <Select
                sx={{ height: 45 }}
                value={selectedVehical}
                onChange={(e) => setSelectedVehical(e.target.value)}
                size="small"
              >
                <MenuItem value={"None"}>None</MenuItem>
                {allRideType?.map((data) => {
                  return (
                    <MenuItem value={data?.value}>{data?.vehicalName}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={4} xs={12}>
            <Typography variant="h3" fontSize={15} py={1} fontWeight={700}>
              Minimum Fare
            </Typography>
            <TextInput
              name={"minFare"}
              type={"number"}
              value={
                settingsData[selectedVehical]?.minfare
                  ? settingsData[selectedVehical]?.minfare
                  : ""
              }
              onChange={onChangeInput}
              placeholder={"Minimum Fare"}
            />
          </Grid>

          <Grid item md={4} xs={12}>
            <Typography variant="h3" fontSize={15} py={1} fontWeight={700}>
              Fare/km
            </Typography>
            <TextInput
              name={"FarePerKm"}
              type={"number"}
              value={
                settingsData[selectedVehical]?.fareKm
                  ? settingsData[selectedVehical]?.fareKm
                  : ""
              }
              onChange={onChangeInput}
              placeholder={"Fare/Hr"}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <Typography variant="h3" fontSize={15} py={1} fontWeight={700}>
              Fare/Hr
            </Typography>
            <TextInput
              name={"FarePerHr"}
              type={"number"}
              value={
                settingsData[selectedVehical]?.fareHr
                  ? settingsData[selectedVehical]?.fareHr
                  : ""
              }
              onChange={onChangeInput}
              placeholder={"Fare/Hr"}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <Typography variant="h3" fontSize={15} py={1} fontWeight={700}>
              Convience Fees
            </Typography>
            <TextInput
              name={"ConvienceFees"}
              type={"number"}
              value={
                settingsData[selectedVehical]?.convenceFees
                  ? settingsData[selectedVehical]?.convenceFees
                  : ""
              }
              onChange={onChangeInput}
              placeholder={"Convience Fees"}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <Typography
              variant="h1"
              sx={{ fontSize: 30, fontWeight: 700, padding: "20px 0" }}
            >
              Set Commission
            </Typography>
          </Grid>

          <Grid item md={4} xs={12}>
            <Typography variant="h3" fontSize={15} py={1} fontWeight={700}>
              Commision
            </Typography>
            <TextInput
              name={"Commision"}
              value={commissionVal}
              type={"number"}
              onChange={(e) => setCommissionVal(e.target.value)}
              placeholder={"Commision"}
            />
          </Grid>
        </Grid>

        <Grid
          item
          md={12}
          xs={12}
          my={3}
          display={"flex"}
          justifyContent={"flex-end"}
        >
          <Box height={45} width={160}>
            <CustomButton handlePressBtn={handlePress} btnLabel={"Update "} />
          </Box>
        </Grid>
      </Grid>
      <Loader isloading={isloading} />
    </NavigationDrawer>
  );
};

export default Settings;
